<template>
    <v-card outlined class="gst-search-result-tabs-no-data d-flex align-center flex-column py-5 px-2">
        <BaseIcon :symbol-id="symbolId" class="mb-4" />
        <v-card-title v-if="title" class="d-flex justify-center pa-0">
            {{ title }}
        </v-card-title>
        <v-card-text v-if="content" class="d-flex justify-center pa-0">
            {{ content }}
        </v-card-text>
    </v-card>
</template>
<script>
    import entityConstants from '@core/utils/constants/entity';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'SearchResultTabsNoData',
        components: {
            BaseIcon
        },
        props: {
            title: {
                type: String,
                default: ''
            },
            content: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: entityConstants.TYPES.EVENT
            },
        },
        computed: {
            symbolId() {
                if ( this.type === entityConstants.TYPES.ATTRACTION ) {
                    return 'icons--microphone_bordered';
                } else if ( this.type === entityConstants.TYPES.EVENT ) {
                    return 'icons--ticket_bordered';
                } else if ( this.type === entityConstants.TYPES.VENUE ) {
                    return 'icons--venue_bordered';
                } else {
                    return null;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-search-result-tabs-no-data {
        border-radius: border-radius( 'xs' ) !important;
        border-color: theme-color( 'quinary' );

        .v-card__title {
            line-height: line-height( 'xxxl' );
            margin-bottom: 3px;
            color: theme-color( 'new-quaternary' );
            font-size: font-size( 'l' );
            font-weight: font-weight( 'large' );
            letter-spacing: -0.47px;
        }

        .v-card__text {
            line-height: line-height( 'xxl' );
            color: theme-color( 'new-tertiary' );
            font-size: font-size( 's' );
            font-weight: font-weight( 'regular' );
            letter-spacing: -0.25px;
        }
    }
</style>
