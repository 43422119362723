<template>
    <v-flex class="gst-search-result-tab-venues-filter d-flex flex-wrap">
        <v-flex class="gst-search-result-tab-venues-filter__city d-flex align-center flex-grow-0">
            <span class="d-inline-flex mr-1">
                <BaseIcon symbol-id="icons--location2" class="gst-search-icon-svg" />
            </span>
            <CitySearchStoreDropDown
                v-model="city"
                :data-test-id="$testId('city')"
                :has-current-location-option="true"
                :limit="5"
                :clearable="false"
                :has-search-option="true"
                :toggle-icon-color="false" />
        </v-flex>
    </v-flex>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import CitySearchStoreDropDown from '@tenant/app/components/inputs/CitySearchStoreDropDown.vue';


    export default {
        name: 'SearchResultTabsVenuesFilter',
        components: {
            CitySearchStoreDropDown,
            BaseIcon,
        },
        testIdOptions: {
            keyPrefix: 'searchResult.venuesTab.filter'
        },
        computed: {
            city: {
                get() {
                    return this.$store.state.venues.filter.city;
                },
                set( value ) {
                    this.$emit( 'input', { city : value } );
                }
            }
        }
    };
</script>