<template>
    <BaseButton
        class="gst-ghost-button"
        outlined
        rounded
        :loading="loading"
        @click="$emit('click')">
        <slot></slot>
    </BaseButton>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';

    export default {
        name: 'GhostButton',
        components: {
            BaseButton
        },
        props: {
            placeholder: {
                type: String,
                default: null
            },
            loading: {
                type: [ Boolean, Number ],
                default: false
            }
        },
        emits: [ 'click' ]
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-ghost-button {
        height: 46px !important;
        padding: 0 theme-spacing( 10 ) !important;
        font-size: 16px;
        font-weight: 600;
        text-transform: none;
    }
</style>
